import React from 'react'
import { graphql, Link } from 'gatsby'

import { Layout } from '../layout'
import { Head } from '../components/head'

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Head title="404: Nicht gefunden" />
        <h1>Seite nicht gefunden</h1>
        <p>
          Die Seite, die du versuchst aufzurufen, existiert nicht.{' '}
          <Link to="/">Zur Startseite</Link>.
        </p>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
